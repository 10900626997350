
// Colors
$brand-color: #5eaf30;
$text-color: #333;
$background-color: #fff;
$accent-color: #537148;
$accent-light-color: #739c64;
$accent-text-color: #f2f2f2;

:root {
    font-family: "Lato", "IBM Plex Sans JP", sans-serif;
    font-weight: 400;
    font-style: normal;
}

article > section {
    max-inline-size: 40rem;
    padding-inline: 1rem;
    margin-inline: auto;
}

article > section img {
    max-inline-size: 100%;
}

blockquote {
    margin: 0;
    margin-block: 1rem;
    margin-inline-start: 10%;
    padding: 1rem;
    box-shadow: 0 0 .5rem #ccc;
    text-align: end;
    color: $text-color;
    background-color: #eee;
    font-family: "Zilla Slab", "Klee One", serif;
}

nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: $accent-light-color;
}

h2 {
    padding-inline-start: .5em;
    border-inline-start: solid .25rem $brand-color;
}

.large {
    font-size: 1.5rem;
}

.clear {
    clear: both;
}

.profile-photo-float {
    float: inline-end;
    margin-inline-start: 1rem;
    margin-block-start: .5rem;
    margin-block-end: .5rem;
    border-radius: 50%;
    inline-size: 30%;
    max-inline-size: 10rem;
}

#page-header {
    background-color: #e1f0dd;
    background-image: url("../../assets/img/bg-1.svg");
    background-size: cover;
    padding: 1rem;
}

#top-bar {
    display: flex;
    flex-direction: row;
    // box-shadow: 0 0 .5rem #888;
    background-color: $accent-color;
    color: $accent-text-color;
    background-image: linear-gradient(to right, $accent-color, $accent-light-color);
    padding-inline: 1em;
    padding-block: .5em;
}

#top-bar-title {
    font-size: 1.5rem;
    margin: 2rem 1rem;
    padding: 0;
    font-weight: bold;
    color: $accent-text-color;
    background-color: #5eaf30cc;
    inline-size: max-content;
    max-inline-size: 100%;
}

#logotype {
    max-inline-size: 100vi;
    inline-size: 20rem;
}

#logotype a, #logotype img {
    display: block;
    inline-size: 100%;
}

#logotype-wrapper {
    flex-grow: 1;
}

#top-nav {
    display: flex;
    flex-wrap: wrap;
    column-gap: .5em;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #e1f0dd;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

#top-nav > ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    // flex-wrap: wrap;
    overflow: auto;
}

#top-nav ul > li {
    border-inline-start: solid 2px $brand-color;
    margin-inline: 2px;
    margin-block: .5em;
    min-inline-size: max-content;
}

#top-nav ul > li > a {
    display: block;
    padding-inline: 1em;
    padding-block: .5em;
}

#brand-string {
    background-color: $brand-color;
    text-align: end;
    color: $accent-text-color;
    padding: .5em;
    font-size: 80%;
}

#slogan {
    font-size: 1.25em;
    font-weight: 300;
    padding: 1em;
    padding-block-start: 15%;
    padding-block-end: 15%;
    background-position: center start;
    background-size: cover;
    color: $text-color;
    background-color: #ffffff80;
    font-weight: bold;
    box-sizing: border-box;
    inline-size: 60%;
    max-inline-size: 32rem;
    margin-inline-start: auto;
}

#twitter-timeline {
    border: none;
    margin: 0;
    inline-size: 100%;
    block-size: 64rem;
}

.banner-block {
    font-weight: 700;
    color: transparent;
    background-image: linear-gradient(to bottom, #496a25, #2a6974);
    -webkit-background-clip: text;
    background-clip: text;
    text-align: center;
    font-size: 5vw;
}

.menhera-green {
    background-color: $brand-color;
    color: $accent-text-color;
    padding: 1rem;
    font-size: 150%;
    border-radius: .5rem;
}

#main-footer {
    padding: 1rem;
    font-size: .8rem;
    background-color: #e1f0dd;
    color: $text-color;
}

#main-footer-nav {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
    max-inline-size: 40rem;
    margin-inline: auto;
}

#main-footer-nav > nav {
    max-inline-size: 20rem;
    margin: 1rem;
}

#main-footer-nav > nav > ul {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
}

.author {
    text-align: end;
    font-size: .8rem;
}

#footer-bottom {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

#footer-bottom-start {
    flex: 1;
    min-inline-size: 0;
}

#sdgs-logo {
    inline-size: 25vi;
    max-inline-size: 8rem;
}

#org-structure {
    inline-size: 100%;
}

body {
    margin: 0;
}

button, input, select, textarea {
    font: inherit;
}

a {
    color: inherit;
    text-decoration: inherit;
}
